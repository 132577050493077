<template></template>
<script>
import auth0client from "../../services/auth0client";
import userService from "../../services/user";
import tenantService from "../../services/tenant";
export default {
  data: function () {
    return {};
  },
  created: async function () {
    this.send("app:loading", true);

    let loginClient = await auth0client;

    try {
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await loginClient.handleRedirectCallback();
        this.isAuthenticated = await loginClient.isAuthenticated();
        this.user = await loginClient.getUser();
        if (this.isAuthenticated) {
          //set user to session
          this.user.loginType = "Oauth";
          this.user.isAuthenticated = true;
          this.user.token = await loginClient.getTokenSilently();
          await userService.setAuthUser(this.user);
          await tenantService.setUserTenantsFromUserHub();
          await userService.refreshUser()
          /** Try again in case the request to "me" created the new tenants (for old users) */
          if(!tenantService.hasTenantsAssociated()){
            await tenantService.setUserTenantsFromUserHub();
          }

          this.send("user:loggedin", this.user);
          const redirect = localStorage.getItem('redirectUrl');
          if (redirect)
          {
            localStorage.removeItem('redirectUrl')
            await this.$router.push(redirect);
          } else {
            if(tenantService.hasTenantsAssociated()){
              await this.$router.push({name: "dashboard"});
            } else {
              await this.$router.push({ name: "tenant" });
            }
          }
        } else {
          console.log("user not logged in, redirect to login");
          await this.$router.push({ name: "login" });
        }
      } else {
        this.showError("Error with login parameters");
        await this.$router.push({ name: "login" });
      }
    } catch (e) {
      this.showError("Error during login processing");
      console.log(e);
      await this.$router.push({ name: "login" });
    } finally {
    }
  },
  unmounted: function () {
  },
  methods: {},
};
</script>
