<template>
  <v-container class="vw-max-750">
    <v-row>
      <v-col>
        <h1 class="mb-2 fw-normal h2">Connection</h1>
        <v-form>
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>
                <h3 class="h4 fw-normal grey-lighten-1">Urls</h3>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider class="my-1"></v-divider>
            <v-card-text>
              <v-text-field
                v-model="apiUrl"
                type="text"
                label="Api Url"
                id="apiUrl"
                :placeholder="apiUrlPh"
              />
              <v-text-field
                v-model="schedulerUrl"
                type="text"
                label="Scheduler Url"
                id="schedulerUrl"
                :placeholder="schedulerUrlPh"
              />
              <v-text-field
                v-model="webhookUrl"
                type="text"
                label="WebHook Url"
                id="webhookUrl"
                :placeholder="webhookUrlPh"
              />
              <v-text-field
                v-model="egressUrl"
                type="text"
                label="Egress Url"
                id="egressUrl"
                :placeholder="egressUrlPh"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            <v-btn depressed rounded large color="primary" @click="save" class="mr-0">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- <div class="float-right">
            <v-btn depressed rounded large color="primary" @click="save" class="mt-10">Save</v-btn>
          </div> -->
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col> 
        <h1 class="mb-2 fw-normal h2">Cache</h1>
        <v-card>
            <v-toolbar flat style="padding-left: 6px;">
              <v-toolbar-title>
                <h3 class="h4 fw-normal grey-lighten-1">Refresh Flows Graphics</h3>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn depressed rounded large color="primary" class="ml-auto" @click="refreshFlowCache">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "../services/config";
export default {
  name: "Settings",
  beforeMount: async function () {
    this.apiUrl = localStorage.getItem("apiUrl", this.apiUrl);
    this.schedulerUrl = localStorage.getItem("schedulerUrl");
    this.webhookUrl = localStorage.getItem("webhookUrl");
    this.egressUrl = localStorage.getItem("egressUrl");

    this.apiUrlPh = "default is:" + config.apiUrl;
    this.schedulerUrlPh = "default is:" + config.ingressScheduledUrl;
    this.webhookUrlPh = "default is:" + config.ingressWebUrl;
    this.egressUrlPh = "default is:" + config.egressUrl;
  },
  methods: {
    save: async function (data) {
      //Todo: make an http call to check if given data is right
      localStorage.setItem("apiUrl", this.apiUrl);
      localStorage.setItem("schedulerUrl", this.schedulerUrl);
      localStorage.setItem("webhookUrl", this.webhookUrl);
      localStorage.setItem("egressUrl", this.egressUrl);
      this.$router.push({ name: "dashboard" }).catch(() => {});
    },
    refreshFlowCache: async function () {
      this.showSaving("refreshing graphics...");
      let response = await this.apiCall(
        `/api/dashboard/InvalidateCache`,
        "GET",
        {},
        {}
      );
      if (response.status === 200) {
        this.showSuccess("Flows graphics successfully refreshed");
      }
    }
  },
  data: () => ({
    token: "",
    username: "",
    password: "",
    tenant: "",
    apiUrl: "",
    schedulerUrl: "",
    webhookUrl: "",
    egressUrl: "",

    apiUrlPh: "",
    schedulerUrlPh: "",
    webhookUrlPh: "",
    egressUrlPh: "",
  }),
};
</script>
