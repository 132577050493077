<template>
  <v-container fluid>

    <v-dialog class="ips-dialog" v-model="viewing">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ viewingMessage.date }} - {{ viewingMessage.component }}
        </v-card-title>
        <v-card-text >
          <p>{{ viewingMessage.message }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded depressed color="primary" text @click="viewing = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog class="ips-dialog" v-model="editing">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          RAW DATA
        </v-card-title>
        <vue-monaco-editor
        v-model="code"
        height="600px"
        language="json"
        loading="false"
      />
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded depressed color="primary" text @click="editing = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="d-flex align-center ips-filter-search">
      <v-col cols="11">
        <v-text-field v-model="queryTxt" v-on:keyup.enter="filterSearch()">
          <v-menu slot="prepend">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"
                >mdi-tune</v-icon
              >
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in templates" :key="index">
                <v-list-item-title>
                  <v-card @click="suggestQuery(item)">
                    <v-card-title>{{ item.title }}</v-card-title>
                    <v-card-subtitle>{{
                      item.description ?? item.query
                    }}</v-card-subtitle>
                  </v-card>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-text-field>
      </v-col>

      <v-col cols="1" class="d-flex align-center">
        <v-btn depressed rounded color="primary" @click="filterSearch()"  class="mr-2" small><v-icon>mdi-magnify</v-icon></v-btn>
        <v-btn depressed rounded color="error" @click="clearSearch()" small>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="_id"
          class="elevation-1"
          :footer-props="itemsPerPageOptions"
          :options.sync="options"
          :server-items-length="totalCount"
        >
          <template #item.message="{ item }">
            <div
              class="text-truncate"
              style="max-width: 350px;cursor:pointer;"
              @click="showLog(item)"
            >
              {{ item.message }}
            </div>
          </template>
          <template #item.actions="{ item }">
              <v-icon @click="edit(item)" style="cursor:pointer;margin-right: 10px"
                >mdi-eye-outline</v-icon
              >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "Logs",
  props: {
    flowId: {
      type: String,
      required: false,
    },
  },
  computed: {
    moment() {
      return moment
    }
  },
  beforeMount: async function () {
    await this.searchLog();

  },
  watch: {
    options: {
      handler() {
        this.searchLog();
      },
      deep: true,
    },
  },
  methods: {
    suggestQuery: async function (item) {
      console.log(this.queryTxt);
      if (this.queryTxt.length > 0) {
        this.queryTxt = this.queryTxt + "," + item.query;
      } else {
        this.queryTxt = item.query;
      }
    },
    showLog: async function (item) {
      this.viewing = true;
      this.viewingMessage = item;
    },
    edit: async function (item) {
      this.code = JSON.stringify(item, null, 2);;
      this.editing = true;
    },
    setSearch: async function (searchObj) {
      this.queryTxt = JSON.stringify(searchObj)
        .trim()
        .replace(/^{+/, "")
        .replace(/}+$/, "");
      await this.searchLog();
    },
    filterSearch: async function () {
      this.options.page = 1;
      await this.searchLog();
    },
    clearSearch: function () {
      this.queryTxt = "";
    },
    searchLog: async function () {
      this.loading = true;
      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";
      if (baseQuery.length > 1) {
        baseQuery += ",";
      }
      if (this.flowId && this.flowId.length > 0) {
        baseQuery += `"FlowId":"${this.flowId}"`;
      }
      baseQuery += "}";
      this.query = {
        PageNumber: this.options.page,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      };
      if (this.options.sortBy) {
        this.query["Sort"] = [];
        for (var i = 0; i < this.options.sortBy.length; i++) {
          this.query["Sort"].push({
            Field: this.options.sortBy[i],
            Ascending: !this.options.sortDesc[i],
          });
        }
      }
      let response = await this.apiCall(
        "/api/logs/search",
        "POST",
        this.query,
        {}
      );

      if (response.status == 200) {
        this.items = response.data.data;
        this.totalCount = response.data.totalCount;
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.date) {
            item.parsedDate = this.parseDateWithMoment(item.date);
          }
        }
        //console.log(this.items);
      }
      this.loading = false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
  data: () => ({
    templates: [
      {
        title: "Between dates",
        query:
          '$and:[{"Date":{ $gte: new Date("2022-12-01")}},{"Date":{ $lte : new Date("2023-12-01")}}]',
      },
      {
        title: "Filter by level",
        query: '"Level":"Info|Debug|Error"',
      },
      {
        title: "In Error",
        query: '"Level":"Error"',
      },
      {
        title: "Search message",
        query: '"Message":/Job/',
      },
      {
        title: "By Component",
        query: '"Component":/Job/',
      },
    ],
    itemsPerPageOptions: { "items-per-page-options": [15, 30, 50, 100, 200] },
    loading: false,
    loadingBuffer: false,
    viewing: false,
    viewingMessage: {},
    query: {},
    sort: {},
    page: 0,
    limit: 100,
    items: [],
    queryTxt: "",
    editingItem: {},
    code : {},
    editing : false,
    pagination: {},
    options: { itemsPerPage: 50, page: 1, sortBy: ["Date"], sortDesc: [true]},
    totalCount: 0,
    headers: [
      { text: "Date", value: "parsedDate" },
      { text: "Level", value: "level" },
      { text: "Logger", value: "logger" },
      { text: "MessageId", value: "messageId" },
      { text: "Message", value: "message" },
      {
        text: "",
        value: "actions",
        active: true,
        sortable: false,
      }

    ]
  }),
};
</script>


<!-- {
  text: "_id",
  sortable: false,
  value: "_id",
}, -->
