<template>
  <v-card flat>
    <v-card-title class="py-1">
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="toolbar-elements">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                  fab
                  text
                  elevation="0"
                  small
                  v-on="onTooltip"
                  @click="addItem"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            Add
          </v-tooltip>
        </slot>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-row>
        <div class="col-6" v-for="key in value" v-bind:key="key.index">
            <v-row>
              <v-col cols="10" class="py-1 pl-5">
                <v-text-field class="pt-0" v-model="key.value" @change="onChange()"/>
              </v-col>
              <v-col cols="2" class="d-flex justify-start align-center py-1 pl-0">
                <div >
                  <v-icon @click="deleteItem(key)">mdi-delete</v-icon>
                </div>
              </v-col>
            </v-row>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import {useVuetifyControl} from '@jsonforms/vue2-vuetify';
import {defineComponent} from 'vue';

import {
  useJsonFormsControl
} from '@jsonforms/vue2';

export default defineComponent({
  name: 'ArrayRender',
  beforeMount: async function () {
    this.value = this.objectToArray(this.control.data ?? {});
    this.title = this.uischema.label;
  },

  setup(props) {
    return useVuetifyControl(
        useJsonFormsControl(props),
        (newValue) => newValue || false
    );
  },
  data: () => ({
    title: "",
    value: [],

  }),
  props: ['data', 'cells', 'uischema', 'schema', 'path', 'enabled', 'rootSchema', 'config', 'id'],
  methods: {
    arrayOfObjectToArrayOfString: (arrayObj) => {
      let arrayString = [];
      if (arrayObj) {
        arrayObj.forEach(x => {
          arrayString.push(x.value);
        });
      }
      return arrayString;
    },
    objectToArray: (obj) => {
      var keys = Object.keys(obj);

      var items = []
      if (keys) {
        let i = 0;
        keys.forEach(x => {
          items.push({value: obj[x]});
        });
      }
      return items;
    },
    deleteItem(item) {
      let index = this.value.indexOf(item);
      if (index !== -1) {
        this.value.splice(index, 1);
      }
      this.onChange();
    },
    addItem() {
      let empties = this.value.filter((item) => item == null || item.value.trim().length === 0);

      if (empties.length > 0) return;
      this.value.push({value: ""});
      this.onChange();
    },
    onChange(event) {
      var data = this.arrayOfObjectToArrayOfString(this.value);
      let path = this.uischema.scope.replace("#\/", "").replaceAll("properties/", "").replaceAll("\/", ".");
      if (this.path === "") {
        this.handleChange(path, data);
      } else {
        this.handleChange(this.path + "." + path, data);
      }
    }
  },

  watch: {
    value: function (oldvalue, newValue) {
      // console.log("value.changed",oldvalue,newValue);
    },
    "control.data": function (oldvalue, newValue) {
      //console.log("controldata.changed",oldvalue,newValue);
      this.value = this.objectToArray(this.control.data ?? {});
    },

    "data": function (oldvalue, newValue) {
      // console.log("data.changed",oldvalue,newValue);
    }
  },
  computed: {}
});
</script>
