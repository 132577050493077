<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-center">
        <p>There are no tenants associated with your user, contact the service manager to be enabled.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logs from "../logs/logs.vue";
import {httpClient} from "@/plugins/extension";
import userService from "@/services/user";
import auth0client from "@/services/auth0client";
import {jwtDecode} from "jwt-decode";

export default {
  name: "TenantWaiting",
  components: {
    logs: logs,
  },
  mounted: function () {

  },
  destroyed: function () {
  },
  methods: {
    createNewTenant: async function () {
      let user = userService.user;

      let response = await httpClient.userHubCall(
          `/users/create-tenant`,
          "POST",
          {
            "email": user.email,
            "tenant": this.newTenantName
          }
      );
      if (response != null && response.status === 200) {
        await userService.setAuthUser(user);
        await userService.refreshUser()
        this.showSuccess("New tenant created");
        window.location.reload();
      } else {
        this.showError("Error creation new tenant");
      }
    },
  },
  data: () => ({
    newTenantName: null
  }),
};
</script>
