<template>
  <v-container>
    <v-tabs>
      <v-tab :key="0">Tenant</v-tab>
      <v-tab :key="1">Raw data</v-tab>
      <v-tab-item :key="0">
        <json-forms :data="objData" :schema="schema" :uischema="uischema"
                    @change="onChangeSourceForm"  :renderers="renderers" ref="formdbase"
                    :validationMode="'ValidateAndShow'" :ajv="ajv"
        />
      </v-tab-item>
      <v-tab-item :key="1">
        <vue-monaco-editor v-model="code" height="600px" language="json" loading="false" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>


<script>

import { JsonForms } from "@jsonforms/vue2";
import Vuetify from "vuetify";
import Vue from "vue";
import schemas from "../../services/schemas";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import {vuetifyRenderers} from "@jsonforms/vue2-vuetify";
import {getElementByScope} from "@/services/uiFormUtils";
import MonacoRender from "@/components/shared/renderers/MonacoRender.vue";
import AssetRender from "@/components/shared/renderers/AssetRender.vue";
Vue.use(Vuetify);

export default {
  name: "tenant-editor",
  data: () => ({
    inited: false,
    onChangeRecursiveCheck : false,
    renderers: [],
    code: "",
    objData: {},
    schema: {},
    uischema: {},
    user:{},
    ajv:{},
    currentDestType: ""
  }),
  beforeMount: async function () {
    this.ajv = new Ajv({
      allErrors: true,
      verbose: true,
      strict: false,
    });
    addFormats(this.ajv);
    await this.initFormSchema();
    await this.setValueToObjectData(this.value);
  },
  props: ["value", "debug"],
  components: {
    JsonForms,
  },
  methods: {
    checkAndValidate()
    {
      const validate = this.ajv.compile(this.schema);
      let errorMessage = "";
      const valid = validate(this.objData);
      if (valid === false) {
        const errorMessages = [];
        validate.errors.forEach((error, index) => {
          if (error.keyword === "required") 
            {
              if (error.params && error.params.missingProperty) 
              { 
                errorMessages.push(`Property ${error.params.missingProperty} is required`);
              }
            } else 
            {
              if (!error.message.includes('must match "then" schema'))
              {
                errorMessages.push(`Property '${error.instancePath.slice(1)}': ${error.message}`);
              }
            }});
          if (errorMessages.length !== 0) {
            errorMessage = errorMessages.join(" | ");
          } else {
            errorMessage = "Internal Validation Error"
          }
          this.objData.errorMessage = errorMessage;
      }
      console.log("Form Valid: " + valid);
      this.objData.isValid = valid;
    },
    initFormSchema: async function () {
      this.uischema = schemas.getSchemaUi("tenant");
      this.schema = schemas.getSchema("tenant");
    },
    setValueToObjectData: async function (value) {
      this.objData = value;
      this.inited = true;
    },
    async onChangeSourceForm(event, errors) {
      if (!this.onChangeRecursiveCheck && this.inited) {
        this.onChangeRecursiveCheck = true;
        this.objData = event.data;
        let currentObjectData = event.data;
        this.checkAndValidate(); 
        await this.$emit("input", this.objData);

        const codeObj = {... this.objData};
        delete codeObj.errorMessage;
        delete codeObj.isValid;
        this.code = JSON.stringify(codeObj, null, 2);

        if (this.objData !== currentObjectData)
        {
          this.$nextTick(() => {
            this.objData = { ...this.objData };
          });
        }

        this.onChangeRecursiveCheck = false;
      }
    },
  },
  created: async function () {
    this.renderers = vuetifyRenderers;

    this.renderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "asset") {
            return 11;
          }
        } catch {}
        return -1;
      },
      renderer: AssetRender,
    });

    this.renderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "monaco") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MonacoRender,
    });
  },
  computed:{},
  watch: {
    value: async function (newValue, oldValue) {
      if (!this.onChangeRecursiveCheck)
      {
        if (oldValue?.id !== newValue?.id || oldValue.name !== newValue.name)
        {
          await this.initFormSchema();
        }
        this.inited = false;
        await this.setValueToObjectData(this.value);
      }
    }
  },
};
</script>
