<script>

import PlatformEditor from "@/components/shared/PlatformEditor.vue";
import AbstractPlatform from "@/components/platforms/abstractPlatform.vue";
import PlatformService from "@/services/platformService";
import userService from "@/services/user";

export default {
  name: "PlatformGlobal",
  components: {PlatformEditor},
  extends: AbstractPlatform,
  beforeMount: async function ()  {
    await this.searchPlatform();
    this.tags = this.getTags();
    this.groups = this.getGroups();
  },
  computed:{
    userService: function(){
      return userService;
    },
  },
  methods: {
    /** Override of parent class - DON'T REMOVE*/
    canWritePlatform: function(){
      return userService.canWriteInfrastructureGlobal();
    },
    searchPlatform: async function () {
      this.loading = true;
      let platforms = await this.platformService.getPlatformsGlobal();

      this.platforms = platforms;
      this.platformsFiltered = platforms;
      this.loading = false;
    },
    insert: async function (data) {
      await this.baseInsert(data, true);
    },
    update: async function (data) {
      await this.baseUpdate(data, true);
    },
    deleteItem: async function () {
      await this.baseDelete(true);
    },
    uploadFile: async function (data) {
      await this.baseUploadFile(data,true);
    },
    deleteFile: async function () {
      await this.baseDeleteFile(true);
    },
  },
};
</script>