<template>
  <v-card flat>
    <v-card-title class="py-1">
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-row>
        <img v-if="showImage" :src="previewUrl()" style="max-width: 200px; max-height: 200px;"/>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import {useVuetifyControl} from '@jsonforms/vue2-vuetify';
import {defineComponent} from 'vue';
import config from "../../../services/config";

import {
  useJsonFormsControl
} from '@jsonforms/vue2';

export default defineComponent({
  name: 'AssetRender',
  beforeMount: function () {
    this.title = this.uischema.label;
    this.value = this.control.data ?? "";
    this.showImage = this.value && this.value.length > 0;
    this.$forceUpdate();
  },
  setup(props) {
    return useVuetifyControl(
        useJsonFormsControl(props),
        (newValue) => newValue || false
    );
  },
  data: () => ({
    title: "",
    value: "",
    showImage: false,
  }),
  props: ['data', 'cells', 'uischema', 'schema', 'path', 'enabled', 'rootSchema', 'config', 'id'],
  methods: {
    previewUrl: function () {
      if(this.value){
        return config.assetsUrl + this.value;
      }

      return "";
    },
  },
  watch: {
    value: function (newvalue, oldvalue) {
      //  console.log("value.changed.oldvalue ",oldvalue);
      //  console.log("value.changed.newvalue ",newvalue);
    },
    "control.data": function (newvalue, oldvalue) {
      // console.log("controldata.changed.oldvalue ", oldvalue);
      // console.log("controldata.changed.newvalue ", newvalue);

      this.value = newvalue ?? "";
      this.showImage = this.value && this.value.length > 0;
    },

    "data": function (newvalue, oldvalue ) {
      // console.log("data.changed",oldvalue,newvalue);
    }
  }
});
</script>
