<template>
  <v-select
      v-model="value"
      :items="filteredPlatforms"
      item-value="id"
      item-text="name"
      @change="onChange"
      clearable
      label="Select Platform">

    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
              v-model="search"
              label="Search Platform"
              clearable
              hide-details
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <img class="dropdown-icon" :src="logoUrl(item.icon)" alt="">{{ item.name }}
    </template>

    <template v-slot:item="{ item }">
      <img class="dropdown-icon" :src="logoUrl(item.icon)" alt="">{{ item.name }}
    </template>
  </v-select>
</template>
<script>

import {useVuetifyControl} from '@jsonforms/vue2-vuetify';
import {defineComponent} from 'vue';

import {
  useJsonFormsControl
} from '@jsonforms/vue2';
import config from "@/services/config";

export default defineComponent({
  name: 'PlatformRender',
  inject: ['platformService'],
  beforeMount: async function () {
    this.value = this.control.data ?? "";
    this.platforms = await this.platformService.getAllPlatforms();
  },

  setup(props) {
    return useVuetifyControl(
        useJsonFormsControl(props),
        (newValue) => newValue || false
    );
  },
  data: () => ({
    platforms: [],
    value: "",
    search: ""
  }),
  props: ['data', 'cells', 'uischema', 'schema', 'path', 'enabled', 'rootSchema', 'config', 'id'],
  methods: {
    onChange(event) {
      let data = this.value ?? "";
      let path = this.uischema.scope.replace("#\/", "").replaceAll("properties/", "").replaceAll("\/", ".");
      if (this.path === "") {
        this.handleChange(path, data);
      } else {
        this.handleChange(this.path + "." + path, data);
      }
    },
    logoUrl: function (logoUrl) {
      if(logoUrl){
        return config.assetsUrl + logoUrl;
      }

      return '/img/platform-placeholder.svg';
    },
  },
  watch: {
    value: function (oldvalue, newValue) {
      // console.log("value.changed",oldvalue,newValue);
    },
    "control.data": function (oldvalue, newValue) {
      //console.log("controldata.changed",oldvalue,newValue);
      this.value = this.control.data ?? "";
    },

    "data": function (oldvalue, newValue) {
      // console.log("data.changed",oldvalue,newValue);
    }
  },
  computed: {
    filteredPlatforms() {
      return this.platforms.filter(platform =>
          platform.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  }
});
</script>

<style>
.dropdown-icon{
  max-width: 20px;
  max-height: 20px;
  height: auto;
  width: auto;
  margin-right: 8px;
}
</style>