class EnumService {

    constructor() {}

    getEnvironmentLabel(environment) {
        if (environment === "prod")
        {
            return "Production";
        }
        else if (environment === "test")
        {
            return "Test";
        }
        else
        {
            return environment;
        }

    }
}


export default new EnumService();
