<template>
  <v-container class="ips-flex">
    <v-menu bottom right style="z-index: 100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="canWritePlatform()"
            elevation="24"
            fab
            fixed
            large
            right
            style="z-index: 100"
            top
            v-bind="attrs"
            @click="newItem"
            v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <v-card class="mb-4">
      <v-card-title>Filters</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-lg-3">
            <v-text-field
                v-model="filters.name"
                dense
                hide-details
                outlined
                placeholder="Name"
                @input="updateFilters">
            </v-text-field>
          </v-col>
          <v-col class="col-lg-3">
            <v-select
                v-model="filters.group"
                :items="groups"
                dense
                hide-details
                outlined
                placeholder="Group"
                @change="updateFilters"
            ></v-select>
          </v-col>
          <v-col class="col-lg-6">
            <v-select
                v-model="filters.tags"
                :items="tags"
                chips
                dense
                hide-details
                label="Select Tags"
                multiple
                outlined
                @change="updateFilters"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-if="data.item"
                    :input-value="data.selected"
                    class="d-inline-flex my-2"
                    close
                    v-bind="data.attrs"
                    @click:close="removeTag(data.item)"
                >
                  {{ capitalize(data.item) }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

        </v-row>
        <div class="d-flex justify-end mt-4">
          <v-btn color="error" depressed medium rounded @click="clearFilters"> Reset</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <div v-for="(group, i) in groups" :key="i">
      <template v-if="platformsFiltered.filter(platform => { return platform.group === group}).length > 0">
        <div class="mt-7 mb-1">
          <span style="color:#757575;" class="font-weight-bold">{{capitalize(group)}}</span>
        </div>
      </template>
      <div v-if="platformsFiltered.filter(platform => { return platform.group === group}).length > 0" class="row ips-row d-flex flex-wrap">
          <v-col v-for="(platform, i) in platformsFiltered" :key="i" v-if="platform.group === group" class="ips-col col-xl-3 col-lg-4 col-md-6 col-sm-12">
            <v-card class="d-flex flex-column ips-card" height="100%">
              <div class="d-inline-flex">
                <div class="d-flex align-center justify-center mx-3 my-5">
                  <img alt="" class="logo-image" v-bind:src="logoUrl(platform.icon) ?? '/img/platform-placeholder.svg'"/>
                </div>
                <div>
                  <v-card-title>{{ platform.name }}</v-card-title>
                  <v-card-subtitle class="pb-2 truncated-description">{{ platform.description }}</v-card-subtitle>
                  <div class="ml-3">
                    <v-chip
                        v-for="(tag, index) in platform.tags"
                        small
                        :key="index"
                        color="grey-lighten-1"
                        class="my-2 mx-1"
                    >
                      {{ tag }}
                    </v-chip>
                  </div>

                </div>
              </div>
              <v-card-actions class="py-1 ips-card-actions mt-auto" style="min-height: 40px;">
                <v-menu bottom right>
                  <template v-if="canWritePlatform()" v-slot:activator="{ on, attrs }">
                    <v-btn color="blue-grey darken-1" depressed icon left rounded v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="edit(platform)">
                      <v-list-item-title>
                        <v-icon>mdi-pencil-outline</v-icon>
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editLogo(platform)">
                      <v-list-item-title>
                        <v-icon>mdi-image-edit</v-icon>
                        Edit Logo
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="startDelete(platform)">
                      <v-list-item-title>
                        <v-icon>mdi-trash-can</v-icon>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-col>
      </div>
    </div>


    <v-dialog v-model="dialog" class="ips-dialog" hide-overlay persistent>
      <v-card min-height="80vh">
        <v-toolbar color="grey darken-4" dark fixed>
          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save"> Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <platform-editor v-model="editingItem" :value="editingItem"/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleting" class="ips-dialog" width="400">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirm before delete
        </v-card-title>
        <v-card-text> Delete {{ this.editingItem.name }}?</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleting = false">
            Cancel
          </v-btn>
          <v-btn color="error" @click="deleteItem()">
            <v-icon>mdi-trash-can</v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog per l'upload -->
    <v-dialog v-model="editingLogoDialog" max-width="500px">
      <v-card>
        <v-toolbar color="grey darken-4" dark fixed>
          <v-btn dark icon @click="closeFileDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title class="headline">Upload del File</v-card-title>

        <v-card-text>
          <div v-if="editingLogoItem?.icon?.length ?? 0 > 0" class="mt-4">
            <p>Actual logo:</p>
            <img :src="actualLogo" alt="Actual logo" style="max-width: 100%; max-height: 200px;">
          </div>

          <v-file-input
              label="Seleziona file"
              v-model="file"
              prepend-icon="mdi-camera"
              :rules="rules"
              accept="image/*"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" @click="deleteFile">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="uploadFile(file)">
            Save
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import PlatformEditor from "@/components/shared/PlatformEditor.vue";
import {capitalize} from "@vue/runtime-dom";
import {httpClient} from "@/plugins/extension";
import config from "@/services/config";

export default {
  name: "AbstractPlatform",
  components: {PlatformEditor},
  abstract: true,
  inject: ['platformService'],
  data: () => ({
    platforms: [],
    platformsFiltered: [],
    filters: {
      name: '',
      group: '',
      tags: []
    },
    groups: [],
    tags: [],
    editingItem: {},
    editingTitle: "",
    dialog: false,
    loading: false,
    deleting: false,
    editingLogoDialog: false,
    editingLogoItem: {},
    file: null,
    actualLogo: null,
    rules: [
      value => { return !value || !value.length || value[0].size < 2000000 || 'Logo size should be less than 2 MB!' },
    ],
  }),
  beforeMount: async function () {
  },
  methods: {
    capitalize,
    /** Override in child class */
    canWritePlatform: function(){
      return false;
    },
    insert: async function (data) {
      throw new Error("The method 'insert' must be implemented in the derived class.");
    },
    update: async function (data) {
      throw new Error("The method 'update' must be implemented in the derived class.");
    },
    deleteItem: async function () {
      throw new Error("The method 'deleteItem' must be implemented in the derived class.");
    },
    save: async function (data) {
      let responseStatus = 0;
      this.showSaving("Platform is saving...");

      if (this.editingItem.isValid === false) {
        this.showError(this.editingItem.errorMessage)
      } else {
        delete this.editingItem.isValid;
        delete this.editingItem.errorMessage;
        if (this.editingItem.id === undefined || this.editingItem.id === "") {
          responseStatus = await this.insert(this.editingItem);
        } else {
          responseStatus = await this.update(this.editingItem);
        }

        if (responseStatus === 200) {
          window.location.reload();
        }
      }
    },
    uploadFile: async function (data) {
      throw new Error("The method 'uploadFile' must be implemented in the derived class.");
    },
    deleteFile: async function () {
      throw new Error("The method 'deleteFile' must be implemented in the derived class.");
    },
    edit: async function (data) {
      this.code = JSON.stringify(data, null, 2);
      this.editingItem = data;
      this.dialog = true;
      this.editingTitle = "Editing " + data.name;
    },
    baseInsert: async function (data, isGlobal) {
      data.tags = this.clearArray(data.tags);
      let platformUrl = isGlobal ? "platforms-global" : "platforms";

      let response = await this.apiCall(`/api/${platformUrl}`, "POST", data, {});
      if (response.status === 200) {
        let replacement = response.data.payload.content;
        this.platforms.push(replacement);
        this.editingItem.id = replacement.id;
        this.showSuccess(`Platform added with id ${replacement.id}`);
        window.location.reload();
      }
      return response.status;
    },
    baseUpdate: async function (data, isGlobal) {
      data.tags = this.clearArray(data.tags);
      let platformUrl = isGlobal ? "platforms-global" : "platforms";

      let response = await this.apiCall(
          `/api/${platformUrl}/${this.editingItem.id}`,
          "PUT",
          data,
          {}
      );
      if (response.status === 200) {
        let replacement = response.data.payload.content;
        let idx = this.platforms.findIndex(
            (x) => x.id === this.editingItem.id,
            this
        );

        this.platforms.splice(idx, 1, replacement);
        this.showSuccess("Platform updated");
        window.location.reload();
      }
      return response.status;
    },
    baseDelete: async function (isGlobal) {
      this.showSaving("Deleting Item " + this.editingItem.name);
      let platformUrl = isGlobal ? "platforms-global" : "platforms";

      let response = await this.apiCall(
          `/api/${platformUrl}/${this.editingItem.id}`,
          "DELETE",
          {},
          {}
      );
      if (response.status === 200) {
        this.showSuccess("Item deleted");
        let idx = this.platforms.findIndex(
            (x) => x.id === this.editingItem.id,
            this
        );
        this.platforms.splice(idx, 1);
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
      this.deleting = false;
    },
    startDelete: async function (data) {
      this.editingItem = data;
      this.deleting = true;
    },
    newItem: async function () {
      let newPlatform = {
        name: undefined,
        group: undefined,
        description: undefined,
        icon: undefined,
        tags: undefined
      };
      this.code = JSON.stringify(newPlatform, null, 2);
      this.dialog = true;
      this.editingTitle = "New Platform";
      this.editingItem = newPlatform;
    },
    clearArray: function (array) {
      if (array !== undefined && array.length > 0) {
        return array
            .map(item => item && item.trim())
            .filter(item => item);
      } else {
        return array;
      }
    },
    updateFilters: function (data) {
      this.platformsFiltered = this.platforms.filter((platform) => {
        return platform.name.toLowerCase().includes(this.filters.name.toLowerCase()) &&
            platform.group.toLowerCase().includes(this.filters.group.toLowerCase()) &&
            (this.filters.tags.length === 0 || platform.tags.filter((n) => {return this.filters.tags.indexOf(n) > -1;}).length > 0);
      });
    },
    clearFilters() {
      this.filters.name = '';
      this.filters.group = '';
      this.filters.tags = [];
      this.updateFilters();
    },
    getTags: function () {
      return [...new Set(this.platforms.flatMap(obj => obj.tags))];
    },
    getGroups: function () {
      return [...new Set(this.platforms.flatMap(obj => obj.group))];
    },
    removeTag: function (item) {
      let index = this.filters.tags.indexOf(item);
      if (index !== -1) {
        this.filters.tags.splice(index, 1);
      }
      this.updateFilters();
    },
    closeFileDialog: function(){
      this.editingLogoDialog = false;
      this.file = null;
    },
    editLogo: async function (data) {
      this.editingLogoItem = data;
      this.editingLogoDialog = true;

      this.actualLogo = this.logoUrl(data.icon);
    },
    baseUploadFile: async function (file, isGlobal) {
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("formFile", file);

      let platformUrl = isGlobal ? "platforms-global" : "platforms";

      let updateUrl = '/api/' + platformUrl + `/${this.editingLogoItem.id}/update-logo`;
      let response = await httpClient.apiCall(
          updateUrl,
          "POST",
          formData,
          null,
          "multipart/form-data"
      );

      if (response.status !== 200) {
        this.showError("Error updating logo");
      }

      const itemToUpdate = this.platforms.find(item => item.id === this.editingLogoItem.id);
      if (itemToUpdate) {
        itemToUpdate.icon = response.data;
      }

      this.editingLogoDialog = false;
      this.editingLogoItem = {};
      this.file = null;

      this.showSuccess("Platform logo successfully updated");
    },
    baseDeleteFile: async function (isGlobal) {
      let platformUrl = isGlobal ? "platforms-global" : "platforms";
      let updateUrl = '/api/' + platformUrl + `/${this.editingLogoItem.id}/update-logo`;
      let response = await httpClient.apiCall(
          updateUrl,
          "POST",
          null,
          null,
          "multipart/form-data"
      );

      if (response.status !== 200) {
        this.showError("Error deleting logo");
      }

      const itemToUpdate = this.platforms.find(item => item.id === this.editingLogoItem.id);
      if (itemToUpdate) {
        itemToUpdate.icon = '';
      }

      this.editingLogoDialog = false;
      this.editingLogoItem = {};
      this.file = null;

      this.showSuccess("Platform logo successfully deleted");
    },
    logoUrl: function (logoUrl) {
      if(logoUrl){
        return config.assetsUrl + logoUrl;
      }

      return null;
    },
  },
};
</script>

<style scoped>
.logo-image {
  max-width: 60px;
  max-height: 60px;
  height: auto;
  width: auto;
}

.truncated-description {
  height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
</style>