import EventBus, {httpClient} from "@/plugins/extension";

class UserService {

    constructor() {
    }

    get user() {
        return JSON.parse(localStorage.getItem("user"));
    }

    set user(user){
        localStorage.setItem("user", JSON.stringify(user));
    }

    remove() {
        localStorage.removeItem("user");
        this.isUserLoggedIn = false;
    }

    isUserAuthenticated(){
        return this.user != null && this.user.isAuthenticated;
    }

    async getUserFromUserHub() {
        let user = await httpClient.userHubCall(
            `/users/me/token/applications/flowlyze`,
            "GET"
        );

        return user?.data;
    }

    async refreshUser(){
        let user = this.user;

        const existentUser = await this.getUserFromUserHub();
        user.tenant = "";
        user.role = "";
        user.permissions = [];

        if(existentUser){
            user.tenant = existentUser.tenant;
            user.role =  existentUser.role;
            user.permissions = existentUser.permissions;
            this.user = user;
        }
    }

    async setAuthUser(user) {
        if (user.loginType === "ApiKey") {
            // todo: manage by user service...
            user.picture = "https://i1.wp.com/cdn.auth0.com/avatars/ak.png?ssl=1";
            user.nickname = "Apikey User";
            user.email = "";
            user.hasBetaFeatures = false;
        }

        this.user = user;
    }

    isSuperAdmin(){
        return this.user?.role === "SUPERADMIN" ?? false;
    }

    isAdmin(){
        return this.user?.role === "ADMIN" ?? false;
    }

    hasPermission(permission) {
        return this.user?.permissions?.includes(permission) ?? false;
    }
    canReadFlows() {
        return this.hasPermission("flows:read");
    }
    canWriteFlows() {
        return this.hasPermission("flows:write");
    }
    canReadBuffer() {
        return this.hasPermission("buffer:read");
    }
    canReadFlowlogs() {
        return this.hasPermission("flowlogs:read");
    }
    canReadMessageInfos() {
        return this.hasPermission("messageinfos:read");
    }
    canWriteMessageInfos() {
        return this.hasPermission("messageinfos:write");
    }
    canReadReports() {
        return this.hasPermission("reports:read");
    }
    canReadInfrastructure() {
        return this.hasPermission("infrastructure:read");
    }
    canWriteInfrastructure() {
        return this.hasPermission("infrastructure:write");
    }
    canReadInfrastructureGlobal() {
        return this.hasPermission("infrastructureglobal:read");
    }
    canWriteInfrastructureGlobal() {
        return this.hasPermission("infrastructureglobal:write");
    }
    canRunFlows() {
        return this.hasPermission("flows:run");
    }
    canReadPipelines() {
        return this.hasPermission("pipelines:read");
    }
    canWritePipelines() {
        return this.hasPermission("pipelines:write");
    }
}

export default new UserService();
