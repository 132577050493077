import {httpClient} from "@/plugins/extension";

class TenantService {
    constructor() {
    }

    get tenant() {
        return JSON.parse(localStorage.getItem("tenant"));
    }

    get tenants() {
        return JSON.parse(localStorage.getItem("tenants"));
    }

    setTenant(tenant) {
        localStorage.setItem("tenant", JSON.stringify(tenant));
    }

    setTenants(tenants) {
        localStorage.setItem("tenants", JSON.stringify(tenants));
    }

    remove() {
        localStorage.removeItem("tenant");
        localStorage.removeItem("tenants");
    }

    hasTenantsAssociated(){
        return this.tenants && this.tenants.length > 0;
    }

    hasMoreThanOneTenant(){
        return this.tenants && this.tenants.length > 1;
    }

    async setUserTenantsFromUserHub() {
        let tenants = await httpClient.userHubCall(
            `/tenants/me/applications/flowlyze`,
            "GET"
        );


        if (tenants.data && tenants.data.length > 0) {
            this.setTenants(tenants.data);
            this.setTenant(tenants.data[0].name);
        }else{
            this.setTenant([]);
            this.setTenant("");
        }
    }
}

export default new TenantService();
