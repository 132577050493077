<template>
  <v-container fluid>
    <v-menu bottom right style="z-index: 100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            elevation="24"
            fab
            fixed
            large
            right
            style="z-index: 100"
            top
            v-bind="attrs"
            @click="createTenant"
            v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-menu>
    <v-card class="mb-4">
      <v-card-title>Filters</v-card-title>
      <v-card-text>
        <div class="d-inline-flex justify-end">
          <v-text-field
              v-model="searchTenantName"
              dense
              hide-details
              outlined
              @keyup.enter="searchTenant"
              placeholder="Tenant Name">
          </v-text-field>
          <v-btn class="mx-5" color="primary" depressed medium rounded @click="searchTenant">Search</v-btn>
          <v-btn color="error" depressed medium rounded @click="clearFilters">Reset</v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="_id"
          class="elevation-1"
          :footer-props="itemsPerPageOptions"
          :options.sync="options"
          :server-items-length="totalCount"
        >
          <template #item.edit="{ item }">
            <v-row>
              <v-col cols="2" class="d-flex align-center">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn icon  v-on="on" @click="editTenant(item)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit tenant's info</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="editLogo(item)">
                      <v-icon>mdi-image-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit tenant's logo</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <template #item.emailUsers="{ item }">
            <v-chip
                v-for="(userRole, index) in item.usersRoles"
                :key="index"
                close
                @click:close="removeUser(userRole.email,item.name)"
                class="chip-block ma-2"
            >
              {{ userRole.email }} - {{ userRole.roleName }}
            </v-chip>
          </template>

          <template #item.actions="{ item }">
            <v-row >
              <v-col cols="17" class="mb-0 pb-0">
                <span> Associate existent user </span>
              </v-col>
            </v-row>
            <v-row  class="ma-0 pa-0">
              <v-col cols="7"  class="mt-0 pt-0">
                <v-text-field v-model="item.email"  label="Email"></v-text-field>
              </v-col>
              <v-col cols="3" class="ma-0 pa-0">
                <v-select v-model="item.role" :items="roles" item-text="name" label="Role"></v-select>
              </v-col>
              <v-col cols="2" class="d-flex align-center">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="addUser(item.email, item.name, item.role)">
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add user to the tenant</span>
                </v-tooltip>

              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editingDialog" class="ips-dialog" hide-overlay persistent>
      <v-card min-height="80vh">
        <v-toolbar color="grey darken-4" dark fixed>
          <v-btn dark icon @click="onCloseEditTenant()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveTenant()">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <tenant-editor v-model="editingItem" :value="editingItem"/>
      </v-card>
    </v-dialog>

    <!-- Dialog per l'upload -->
    <v-dialog v-model="editingLogoDialog" max-width="500px">
      <v-card>
        <v-toolbar color="grey darken-4" dark fixed>
          <v-btn dark icon @click="editingLogoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title class="headline">Upload del File</v-card-title>

        <v-card-text>

          <div v-if="editingLogoItem?.logo?.length ?? 0 > 0" class="mt-4">
            <p>Actual logo:</p>
            <img :src="actualLogo" alt="Actual logo" style="max-width: 100%; max-height: 200px;">
          </div>

          <!-- Input per il file -->
          <v-file-input
            label="Seleziona file"
            v-model="file"
            prepend-icon="mdi-camera"
            :rules="rules"
            accept="image/*"
            @change="handleFileChange"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" @click="deleteFile">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <!-- Pulsante Conferma -->
          <v-btn color="primary" @click="uploadFile">
            Save
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import TenantEditor from "@/components/shared/TenantEditor.vue";
import {httpClient} from "@/plugins/extension";
import config from "../../services/config";

export default {
  name: "TenantView",
  components: {
    TenantEditor,
  },
  props: {},
  computed: {},
  beforeMount: async function () {
    await this.searchTenant();
    await this.setAllRoles();
  },
  watch: {
    options: {
      handler() {
        this.searchTenant();
      },
      deep: true,
    },
  },
  methods: {
    clearFilters: async function(){
      this.searchTenantName = "";
      this.searchTenant();
    },
    addUser: async function(userEmail, tenant, role){
      this.loading = true;
      let response = await this.userHubCall(
          "/users/applications/flowlyze/add-tenant",
          "POST",
          {
            "email": userEmail,
            "tenant": tenant,
            "role": role
          }
      );

      if (response.status === 200) {
        const itemToUpdate = this.items.find(item => item.name === tenant);
        const existentEmail = itemToUpdate.usersRoles.filter(x => x.email === userEmail);

        if (existentEmail.length === 0) {
          itemToUpdate.usersRoles.push({
            email : userEmail,
            roleName : role
          });
        }
        this.showSuccess("User successfully added to tenant");
      }
      this.loading = false;
    },
    setAllRoles: async function(){
      let response = await this.userHubCall(
          "/roles/applications/flowlyze",
          "GET"
      );

      if (response.status === 200) {
        this.roles = response.data;
      }
    },
    removeUser: async function(userEmail, tenant){
      this.loading = true;
      let response = await this.userHubCall(
          "/users/applications/flowlyze/remove-tenant",
          "POST",
          {
            "email": userEmail,
            "tenant": tenant
          }
      );

      if (response.status === 200) {
        const itemToUpdate = this.items.find(item => item.name === tenant);
        if (itemToUpdate) {
          itemToUpdate.usersRoles = itemToUpdate.usersRoles.filter(x => x.email !== userEmail);
        }
        this.showSuccess("User successfully removed from tenant");
      }
      this.loading = false;
    },
    searchTenant: async function () {
      this.loading = true;
      let response = await this.userHubCall(
        "/tenants/applications/flowlyze",
        "GET",
          {},
          {
            tenant: this.searchTenantName,
            pageSize: this.options.itemsPerPage,
            pageNumber: this.options.page,
            sortBy: this.options.sortBy[0],
            sortDesc: this.options.sortDesc[0]
          }
      );

      if (response.status === 200) {
        this.items = response.data.data;
        this.totalCount = response.data.totalElements;
      }
      this.loading = false;
    },
    editTenant: async function (data) {
      this.code = JSON.stringify(data, null, 2);
      data.new = "false";
      this.editingItem = data;
      this.editingDialog = true;
      this.editingNew = false;
      this.editingTitle = "Editing " + data.name;
    },
    createTenant: async function (data) {
      this.code = JSON.stringify(data, null, 2);
      data.new = "true";
      this.editingDialog = true;
      this.editingNew = true;
      this.editingTitle = "Create New Tenant";
    },
    mockCreate() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            status : 200
          });
        }, 1000); // Simula un ritardo di 1 secondo
      });
    },
    saveTenant: async function (data) {
      this.showSaving("Saving tenant infos..");
      let response;
      if(!this.editingNew) {
        let updateUrl = `/tenants/${this.editingItem.name}/applications/flowlyze`;
        response = await httpClient.userHubCall(
            updateUrl,
            "PUT",
            this.editingItem
        );
      }else{
        let createUrl = `/tenants`;
        response = await httpClient.userHubCall(
            createUrl,
            "POST",
            this.editingItem
        );
      }
      if (response && response.status === 200) {
        if(!this.editingNew) {
          this.showSuccess("Tenant successfully updated");
          // update the item with the same name
          const itemToUpdate = this.items.find(item => item.name === this.editingItem.name);
          if (itemToUpdate) {
            itemToUpdate.description = this.editingItem.description;
          }
        }else{
          this.showSuccess("Tenant successfully created");
          this.reloadComponent();
        }

      } else {
        if(!this.editingNew) {
          this.showError("Error updating tenant");
        }else {
          this.showError("Error creating tenant");
        }
      }
      
      this.editingDialog = false;
    },
    onCloseEditTenant: function () {
      this.code = null;
      this.editingItem = {};
      this.editingDialog = false;
      this.editingTitle = "";
    },
    editLogo: async function (data) {
      this.editingLogoItem = data;
      this.editingLogoDialog = true;

      this.actualLogo = this.previewUrl();
    },
    handleFileChange: function (file) {
      //TODO: mostrare immagine caricata ?
    },
    uploadFile: async function () {
      if (!this.file) {
        return;
      }

      // Esempio di gestione dell'upload (sostituisci con il tuo codice)
      const formData = new FormData();
      formData.append("formFile", this.file);

      // Simulazione di una richiesta API (usa axios o fetch per fare la richiesta reale)
      let updateUrl = `/tenants/${this.editingLogoItem.name}/applications/flowlyze/update-logo`;
      let response = await httpClient.userHubCall(
        updateUrl,
        "POST",
        formData,
        null,
        "multipart/form-data"
      );

      if (response.status !== 200) {
        this.showError("Error updating logo");
      } 

      const itemToUpdate = this.items.find(item => item.name === this.editingLogoItem.name);
      if (itemToUpdate) {
        itemToUpdate.logo = response.data;
      }

      this.editingLogoDialog = false;
      this.editingLogoItem = {};
      this.file = null;

      this.showSuccess("Tenant logo successfully updated");
    },
    deleteFile: async function () {
      let updateUrl = `/tenants/${this.editingLogoItem.name}/applications/flowlyze/update-logo`;
      let response = await httpClient.userHubCall(
        updateUrl,
        "POST",
        null,
        null,
        "multipart/form-data"
      );

      if (response.status !== 200) {
        this.showError("Error updating logo");
      }

      const itemToUpdate = this.items.find(item => item.name === this.editingLogoItem.name);
      if (itemToUpdate) {
        itemToUpdate.logo = "";
      }

      this.editingLogoDialog = false;
      this.editingLogoItem = {};
      this.file = null;

      this.showSuccess("Tenant logo successfully deleted");
    },
    previewUrl: function () {
      if(this.editingLogoItem.logo){
        return config.assetsUrl + this.editingLogoItem.logo;
      }

      return null;
    },
    imageOnly(value) {
      if (!value) return true;
      const isImage = value[0] && value[0].type.startsWith("image/");
      return isImage || "Only image files are allowed.";
    },
    reloadComponent() {
      window.location.reload(); // Cambiamo il valore della chiave per innescare il reload
    }
  },

  data: () => ({
    itemsPerPageOptions: { "items-per-page-options": [15, 30, 50, 100, 200] },
    items: [],
    loading : false,
    searchTenantName: "",
    options: { itemsPerPage: 50, page: 1, sortBy: ["Id"], sortDesc: [false]},
    totalCount: 0,
    roles:[],
    headers: [
      { text: "Id", value: "id" },
      { text: "Name", value: "name" },
      { text: "Edit", value: "edit" },
      {
        text: "Email Users",
        value: "emailUsers",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        active: true,
        sortable: false,
      }

    ],
    editingDialog: false,
    editingNew: false,
    editingTitle: "",
    editingItem: {},
    editingLogoDialog: false,
    editingLogoItem: {},
    file: null,
    actualLogo: null,
    rules: [
        value => { return !value || !value.length || value[0].size < 2000000 || 'Logo size should be less than 2 MB!' },
      ],
  }),
};
</script>

<style>
.chip-block {
  display: block;
  width: fit-content;
}
</style>