import {httpClient} from "@/plugins/extension";

class PlatformService {
    constructor() {
    }

    async getAllPlatforms(){
        const endpoint = `/api/platforms-tenant-global`;
        let response = await httpClient.apiCall(
            endpoint,
            "GET"
        );
        if (response != null && response.status === 200) {
            let platforms = response.data.payload.content;
            return platforms.sort((a,b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0));
        }
    }

    async getPlatformsTenant(){
        return await this.getPlatforms(false);
    }

    async getPlatformsGlobal(){
        return await this.getPlatforms(true);
    }

    async getPlatforms(isGlobal) {
        let query = {
            PageNumber: 1,
            PageSize: 1000,
            RawQuery: {},
            Sort: [],
        };

        const platformUrl = isGlobal ? "platforms-global" : "platforms";
        const endpoint = `/api/${platformUrl}/list`;
        let response = await httpClient.apiCall(
            endpoint,
            "POST",
            query,
            {}
        );
        if (response != null && response.status === 200) {
            let platforms = response.data.payload.content;
            return platforms.sort((a,b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0));
        }
    }
}

export default PlatformService;